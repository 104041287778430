import React, { useEffect, useRef, useState } from "react"
import scriptLoader from 'react-async-script-loader'
import Modal from "../components/Modal"
import Loader from 'react-loader-spinner'
import swal from 'sweetalert2'
import Helmet from "react-helmet"
import GivingImage from '../assets/images/giving/1.jpg'
import AmericanExpressImage from '../assets/images/payment/american-express.svg'
import MasterCardImage from '../assets/images/payment/mastercard.png'
import MaestroImage from '../assets/images/payment/maestro.png'
import DirectDebitImage from '../assets/images/payment/direct-debit.png'
import VisaImage from '../assets/images/payment/visa.png'
import PaypalImage from '../assets/images/payment/paypal.png'
import { changeFormValue } from "../lib/utils"


import Section, { TextSection, ContainerSection } from '../components/Section'
import { sendGiftAidConfirmation, sendGiftAidDetails } from "../lib/API"


const clientId = "AVAT5WRVl3hg20H8PogELRHKSpTJYNwLPKBZ6PI6rc1clnAyO-sCtbC-xidcfeCi1tbV55L4r9YP2TRG"

 /* eslint-disable no-undef */
const Homepage = () => {

    const siteTitle = 'Blackheath and Charlton Baptist Church - Giving'
    const donationTabStyle = {}
    const donationTabStyle2 = { opacity: 0.3, pointerEvents: "none" }
    const formRef = useRef()
    const subscribeModalRef = useRef()
    const [ givingType, setGivingType ] = useState( "" )
    const [ showBankTransferModal, setShowBankTransferModal ] = useState( false )
    const [ loading, setLoading ] = useState( false )
    const [ subscribeEmail, setSubscribeEmail ] = useState( '' )
    const [ subscribeFirstName, setSubscribeFirstName ] = useState( '' )
    const [ subscribeLastName, setSubscribeLastName ] = useState( '' )
    const [ generatedConfirmation, setGeneratedConfirmation ] = useState( null )
    const [ confirmationNumber, setConfirmationNumber ] = useState( '' )
    const [ verified, setVerified ] = useState( false )
    const [ isVerifying, setIsVerifying ] = useState( false )
    



    const submitPaypal = async e => {
        // console.log( e.target.childNodes[0].innerHTML )
        if( formRef.current ) {
            const donation_type = document.getElementById( "donation_type" )
            const targetText = e.target.childNodes[0].innerHTML || e.target.innerHTML || ""
            donation_type.value = targetText !== "Donate Now" ? targetText : ""
            // setGivingType( e.target.childNodes[0].innerHTML || "default" )
            
            // console.log( document.getElementById( "donation_type" ).value )
            formRef.current.submit()
        }
    }

    const closeBankTransferModal = () => {
        setShowBankTransferModal(false)
        setVerified(false)
        setIsVerifying(false)
        setSubscribeEmail( '' )
        setSubscribeFirstName( '' )
        setSubscribeLastName( '' )
        setGeneratedConfirmation( null )
        setConfirmationNumber( '' )
    }

    const swalError = (error) => swal.fire( 'Error', error, 'error' ).then( () => {
        // setSubscribeEmail( '' )
        // setSubscribeFirstName( '' )
        // setSubscribeLastName( '' )
    } )

    const submitGiftAid = async e => {
        e.preventDefault()
        e.stopPropagation()
        const data = { email: subscribeEmail, first_name: subscribeFirstName, last_name: subscribeLastName }
        if( !isVerifying ){
            const code = Math.floor(Math.random() * 899999 + 100000)
            setIsVerifying( true )
            setGeneratedConfirmation( code )
            const error = (error) => {
                setIsVerifying( false )
                setGeneratedConfirmation( null )
                swalError( error )
            }
            
            try{
                const res = await sendGiftAidConfirmation( { ...data, code } )
                if( !res || res.error )
                    error( res.error )
                else
                    setIsVerifying( true )

            }catch(e){
                error( "Could not send confirmation email" )
            }     
        }else{
            // console.log( confirmationNumber, generatedConfirmation)
            const confirmationError = ( error ) => {
                setGeneratedConfirmation( null )
                swalError( error )
                setLoading( false )
            }
            setLoading( true )
            if(confirmationNumber != generatedConfirmation){
                confirmationError("Incorrect confirmation code")
                return
            }
            try{
                const res = await sendGiftAidDetails( data )
                setLoading( false )
                if( res && res.id ) {
                    swal.fire( 'Success', 'Opted in to Gift Aid successfully', 'success' ).then( () => {
                        closeBankTransferModal()
                    } )
                }
                else{
                    swal.fire( 'Could not opt in', 'Already opted in', 'error' )
                    closeBankTransferModal()
                }
            }catch(e){
                confirmationError("Failed to add Gift Aid details")
                closeBankTransferModal()
            }
        }
    }


    return (
        <div id='home-page'>
            <Helmet title={siteTitle} />
            <Modal ref={subscribeModalRef} className="gift-aid" show={showBankTransferModal} handleClose={closeBankTransferModal}>
                    <h2 className="major" style={{color: "black"}}>Give to the Church via Bank Transfer</h2>
                    <div className="bank-details" style={{width: "60%", margin: "0 auto"}}>
                        <span style={{textAlign: "left"}}> HSBC Account name:</span> Blackheath & Charlton Baptist Church <br/>
                        <span style={{textAlign: "left"}}> Sort Code: </span> 40-04-12 <br/>
                        <span style={{textAlign: "left"}}>Account Number: </span> 21434489 <br/>
                    </div>
                    {/* <h3 className="major" style={{color: "black", marginTop: 40}}>Opt in to Gift Aid</h3>
                    <form className="login-form" method="POST" onSubmit={submitGiftAid}>
                        <input className="form-input" type="text" name="fname" value={subscribeFirstName} onChange={e => changeFormValue( e, setSubscribeFirstName )} placeholder="First Name*" required /><br/>
                        <input className="form-input" type="text" name="lname" value={subscribeLastName} onChange={e => changeFormValue( e, setSubscribeLastName )} placeholder="Last Name*" required /><br/>
                        <input className="form-input" type="email" name="email" value={subscribeEmail} onChange={e => changeFormValue( e, setSubscribeEmail )} placeholder="Email*" required /><br/>
                        {isVerifying && 
                            <>
                                <label>Confirmation number sent to your email</label>
                                <input className="form-input" type="number" name="confirmation" value={confirmationNumber} onChange={e => changeFormValue( e, setConfirmationNumber )} required />
                            </>}
                        <br />
                        {!loading ? 
                            <input 
                                type="submit" 
                                value={!isVerifying && !verified ? "Confirm Details" : "Submit"} 
                                className="button special" 
                            /> :
                        <Loader
                            type="Puff"
                            color="#00BFFF"
                            height={40}
                            width={50}
                        />
                        }
                    </form> */}
                </Modal>
            <ContainerSection id="give-now" background={[ GivingImage ]} tint={0.45} blur={0.08} opacity={0.35}>
            <TextSection 
                id="about-giving" 
                title="Giving"
                style="style1"
                description="Our work in our local communities and around the world is 
                made possible by the generous donations of our church family and those who have been blessed by our Ministry
                "    
                tint={0.3}
                

            />
            
              {/* <div style={{textAlign: "center"}} className="payment-options">
                <input type="radio" id="one-off" name="payment-option" value="one-off"/>
                <label for="one-off">One-off Donation</label>

                <input type="radio" id="monthly" name="payment-option" value="monthly"/>
                <label for="monthly">Set as Monthly</label><br/>

                <input type="checkbox" id="gift-aid" name="payment-option" value="gift-aid"/>
                <label for="gift-aid">Gift-Aid Donation</label>
              </div>
              <div className="payment-options">
                  <span style={{ marginRight: "10px" }}> £</span><input type="number" id="amount" name="amount" onChange={( e ) => changeDonationAmount( e )}/>
              </div>
              <div id="dropin-container"></div>
              
              <ul className="actions uniform">
                  <li><div id="giving-button" ref={givingButtonRef} className="button">Continue</div></li>
              </ul> */}
              
              <div className="grid-wrapper donation-tab-container">
                    <div style={donationTabStyle} onClick={submitPaypal} className="col-3 donation-tab">
                        <h2>Tithing</h2>
                        {/* <p>Description of tithing</p> */}
                    </div>
                    <div style={donationTabStyle} onClick={submitPaypal} className="col-3 donation-tab">
                        <h2>One Off</h2>
                        {/* <p>Description</p> */}
                    </div>
                    <div style={donationTabStyle} onClick={submitPaypal} className="col-3 donation-tab">
                        <h2>Offering</h2>
                        {/* <p>Description</p> */}
                    </div>
                    <div style={donationTabStyle} onClick={() => setShowBankTransferModal( true )} className="col-3 donation-tab">
                        <h2>Bank Transfer</h2>
                        {/* <p>Description</p> */}
                    </div>
                    

                    {/* <div style={donationTabStyle} className="col-3 donation-tab">
                        <h2>Legacy</h2>
                        <p>Description</p>
                    </div> */}
                </div>

                


                <div className="col-12 actions payment-methods">
                    <span className='payment-image-container'><img className='image fit payment' src={AmericanExpressImage} /></span>
                    <span className='payment-image-container'><img className='image fit payment' src={MasterCardImage} /></span>
                    <span className='payment-image-container'><img className='image fit payment' src={MaestroImage} /></span>
                    <span className='payment-image-container'><img className='image fit payment' src={DirectDebitImage} /></span>
                    <span className='payment-image-container'><img className='image fit payment' src={VisaImage} /></span>
                    <span className='payment-image-container'><img className='image fit payment' src={PaypalImage} /></span>

                </div>

                <div className="col-12 actions donate-button">
                    <button className="button" style={{ color: 'white', pointerEvents: "none", opacity: 0.3 }} onClick={submitPaypal}>Donate Now</button>
                </div>


              <form className='paypal-form' ref={formRef} action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top" style={{margin: '3em 0'}}>
              <input type="hidden" id="donation_cmd" name="cmd" value="_s-xclick" />
              <input type="hidden" name="hosted_button_id" value="65QXMSBYJUHGQ" />
              <input type="hidden" id="donation_type" name="item_name" value="" />
              <input  className='paypal-donate-button' type="image" src="https://www.paypalobjects.com/en_US/GB/i/btn/btn_donateCC_LG.gif" width="200" height="auto" border="0" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button" />
              <img alt="" border="0" src="https://www.paypal.com/en_GB/i/scr/pixel.gif" width="1" height="1" />
              </form>
            </ContainerSection>
        </div>
    )
}

export default scriptLoader( `https://www.paypal.com/sdk/js?client-id=${clientId}` )( Homepage )